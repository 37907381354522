
.ann_house_icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; 
  height: 70px;
  
}
.ann_house_icon-container > img{
  width: 70px; 
  height: 70px;
}


/*.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active--content {
  font-weight:600;
  color: var(--primary);
}
.navbar--content:hover{
 font-size:20px;
 color:darkgreen;
 text-decoration: underline;
}*/



.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

.footer--img{
 width:50px;
 height:50px;
}

.footer--img:hover{

 opacity: 0.3;
 filter: alpha(opacity=30);

}








@media only screen and (max-width: 1600px) {
 
 }
@media only screen and (max-width: 1024px) {
.ann_house_icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; 
  height: 70px;  
}
.ann_house_icon-container > img{
  width: 70px; 
  height: 70px;  
}
}
@media only screen and (max-width: 1200px) {

  
  .footer--content {
    text-align: center;
  }

  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }

  .footer--container {
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
 
  
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 0px;
  }





  .btn-outline-primary {
    display: none;
  }

.ann_house_icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px; 
  height: 70px;
  
}
.ann_house_icon-container > img{
  width: 70px; 
  height: 70px;
 
}
}








