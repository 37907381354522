
.contactSection {
   
  display: flex;
  padding: 60.333px 85.333px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  gap: 30px;
  height:100%;
  position:relative;
 
  
 
 
}
.contactButton{
border-radius: 10px;
margin-top:20px;
background-color:#FF5A5F;
width:100%;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  row-gap: 20px;
  
  
 
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 12px;
  row-gap: 12px;
  
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: var(--white);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

 
}
.contactOverlay {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
  z-index: 2; /* Below the popup but above the rest of the content */
  border-radius:8px;
}

.contactSuccesspopup {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  max-height: 500px;
  background-color: #fff; /* White background */
  padding: 30px;
  

  text-align: start;
  display:flex;
  flex-direction:column;
  justify-content:center;
  border-radius:8px;
  row-gap:10px;
  font-size:20px;
}

.contactSuccesspopup button {
  margin-top:10px;
   background-color:LimeGreen;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius:3px;
  max-width:70px;
  align-self: flex-end;
}


@media only screen and (max-width:760px) {
.contactSection{
margin-top:30px;
}
}

@media only screen and (max-width:550px) {

  .btn-outline-primary {
    display: none;
  }

 
  .contactSection
 {
    gap: 10px;
    padding:10px 10px;
   font-size:13px;
  
   
  }
  
  .contact--form--container {
    
    background-color:rgb(239, 242, 247);
    width: 80%;
    row-gap: 15px;
    border:1px solid lightgray;
    padding:20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .contact--input {
  padding: 2px;
  border: 1.333px solid lightgray;
  background: var(--white);
  height:25px;
  touch-action: manipulation; 
 
}
  .contact--label {
  
  gap: 5.6px;
  flex: 1 0 0;
  width: 100%;
 
}
.contactSuccesspopup {
  
  font-size:10px;
}

  }



