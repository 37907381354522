h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: DarkSlateGray;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
 
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: #FF5A5F;
}
.btn-primary {
  color: var(--white);
  background-color: #FF5A5F;
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}



/* Heading 1 */
h1 {
 font-family:sans-serif; 
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
  color:#282938;
}
/* Heading 2 */
h2 {
  font-family:sans-serif; 
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
   color:#282938;
}
/* Heading 3 */
h2 {
  color:#282938;
  font-family:sans-serif; 
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/* Portfolio, Testimonial & Contact Me */

.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}


.home--section {
  
  display: flex;
  padding: 0.333px 85.333px;
  margin-top:133.33px;
  flex-direction:column;
  height:100%;
  
  
}
.homePhoto{
  position:relative;
  max-height:500px;
}
.homePhoto > img {
  width: 100%;
  height: 500px; 
  object-fit: cover;
}
  



.indexInner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
 
   
}

.indexInner > h2 {
 position:absolute;
  font-size: 46px;
  font-weight: 700; 
  bottom: 70px; 
  left: 50px;
  color:white;
  

}

#thehome {
  position: relative;
}
button.show-more-button:hover {
  background-color: AliceBlue;
}
button.show-more-button {
  position: absolute;
  bottom: 20px;
  right: 15px;
  padding: 5px 10px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-icon {
  max-width: 15px; /* Adjust as needed */
  max-height: 15px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
  vertical-align: middle;
}

@media only screen and (max-width: 1200px) {
.homePhoto > img {
  height: 480px;
}
.indexInner {
  height: 480px; 
}

.indexInner > h2 {
 position:absolute;
  bottom: 60px; /* Align to the bottom */
  left: 40px;

}


}
@media only screen and (max-width: 1024px) {
.home--section {
  
  display: flex;
  padding: 0.333px 15px;
  margin-top:100.33px;
  flex-direction:column;
  height:100%;
  
  
}
.homePhoto > img {
  height: 480px;
}
.indexInner {
  height: 480px; 
}
.indexInner > h2 {
 position:absolute;
  font-size: 36px;
  font-weight: 700; 
  bottom: 70px; 
  left: 50px;
  color:white;
  

}
}
@media only screen and (max-width: 767px) {
.home--section {
  display: flex;
  padding: 0.333px 15px;
  margin-top:85.33px;
  flex-direction:column;
  height:100%; 
}

.homePhoto > img {
  height: 350px;
}
.indexInner {
  height: 350px; 
}

.indexInner > h2 {
 position:absolute;
 top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;

}


}
@media only screen and (max-width: 480px) {

.indexInner > h2 {
 display:none;
 
}
.home--section {
  display: flex;
  padding: 0.333px 15px;
  margin-top:65.33px;
  flex-direction:column;
  height:100%; 
}

.homePhoto > img {
  height: 250px;
}
.indexInner {
  height: 250px; 
}

button.show-more-button:hover {
  background-color: AliceBlue;
}
button.show-more-button {
  position: absolute;
  bottom: 15px;
  right: 10px;
  padding: 5px 5px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size:8px;
}
.button-icon {
 width: 10px; /* Adjust as needed */
  height: 10px; /* Adjust as needed */
  margin-right: 5px; /* Adjust as needed */
  vertical-align: middle;
}


}
