
.info--section{
  display: flex;
  flex-direction:column;
  padding-bottom:0;
  
 

}
.iconSize{
  max-width: 30px; /* Adjust as needed */
  max-height: 30px; /* Adjust as needed */
  margin-right: 5px; /* Adjust as needed */
  vertical-align: middle;
}

.headIcons{
 display:flex;
  gap: 20px;
  margin-bottom:20px;
 
 

}
.headIconsSpace{
  display: flex;
  align-items: center;
  column-gap:25px;
  row-gap:10px;
 flex-wrap:wrap;
 font-size:15px;

}


.profileSize{
 max-width:40px;
 max-height:40px;
 margin-left: 0px; 
 margin-bottom:5px;

}
.profile{
  display: flex;
  align-items: center;
  border-bottom:1px solid lightgray;
}

.profile span{
 font-weight: bold;
  font-family: sans-serif;
  margin-left: 5px;

}
.headIcons span {
  font-weight: bold;
  font-family: sans-serif;
  margin-left: 5px; /* Adjust as needed for space between image and text */
}

.InfoButton {
 padding-right: 0px;
  background-color:white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:600;
  color: black; 
  text-decoration: underline;
  border-radius: 5px;
  transition: all 0.3s ease;
  border:none;
}

.InfoButton:hover {
  background-color:lightGray;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  
}

.sectionsPadding{
 border-bottom:1px solid lightgray;
 padding-top:20px;
 padding-bottom:20px;


}



.bedroomsDispaly{
 display:flex;
 flex-direction:row;
 gap:10px;
 flex-wrap:wrap;
 
}
.singleBedRoom{
 display:flex;
 flex-direction:column;
 gap:10px;
 width:180px;
 border:1px solid lightgray;
 padding:20px;
 border-radius: 10px;
 box-shadow: 0 0 15px rgba(128, 128, 128, 0.3); 
}
.singleBedRoom>span>img{
 max-width:30px;
 max-height:30px;
 margin-right:10px;

}

.amenities{
display:flex;
flex-wrap:wrap;
gap:10px;


}
.amenities > * {
 
  flex: 0 0 calc(30% - 10px);  /* 50% width minus the gap */
}

.sectionsPadding button{
 margin-top:10px;
color:'black';

}
@media only screen and (max-width: 1400px) {
.amenities > * {
 
  flex: 0 0 calc(40% - 10px);  /* 50% width minus the gap */
}
 

}






@media only screen and (max-width: 1200px) {
.info--section{
  display: flex;
  padding: 0.333px 0px;
  flex-direction:column;
  padding-bottom:0;
 

}
.amenities > * {
 
  flex: 0 0 calc(30% - 10px);  /* 50% width minus the gap */
}
 .iconSize{
  max-width: 20px; /* Adjust as needed */
  max-height: 20px; /* Adjust as needed */
  margin-right: 5px; /* Adjust as needed */
  vertical-align: middle;
 
}


.headIconsSpace{
  display: flex;
  align-items: center;
  column-gap:25px;
  row-gap:10px;
 flex-wrap:wrap;
 font-size:13px;
 
}
.sectionsPadding{
 font-size:14px;
}
.profile{
font-size:14px;
}
}
@media only screen and (max-width: 1024px) {
.info--section{
  display: flex;
  padding: 0.333px 15.333px 0px 15.333px ;
  
  flex-direction:column;
}
.amenities > * {
 
  flex: 0 0 calc(30% - 0px);  /* 50% width minus the gap */
}

}
@media only screen and (max-width: 767px) {
.info--section{ 
 padding: 0.333px 15.333px 0px 15.333px ;
  padding-bottom: 0px;
}
.bedroomSection{
display:flex;
flex-wrap:wrap;


}
.amenities > * {
  flex: 0 0 calc(50% - 5px);
 /* 50% width minus the gap */
}

}
@media only screen and (max-width: 480px) {
 .info--section{
  padding-top:0px;
  padding-bottom: 0px;
  
}
.singleBedRoom{
 
 gap:5px;
 width:160px;
 
}
.sectionsPadding{
 font-size:15px;
}
}
@media only screen and (max-width: 350px) {
 .amenities > * {
  flex: 0 0 calc(60% - 10px);
 /* 50% width minus the gap */
}
}
