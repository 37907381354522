.reviewSection{
  display:flex;
  flex-direction:column;
  gap:10px;
  padding: 33.333px 85.333px;
  padding-top:50px;
  min-height:200px;
  height:100%;
  position:relative;
  
  
}

.card--content{
  
   display: flex;
  flex-wrap: wrap;
  gap: 20px;
 
 
  
 
}
.singleCard{
  flex: 0 1 calc(50% - 10px); 
 border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid lightgray;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
   padding:30px;
  
}
.reviewProfileImg{
 width:40px;
 height:40px;
}

.reviewerTitleBox{
 display:flex;
  align-items: center;
  gap:5px;
}
.card--content>button{

 height:2em;
 font-size:1em;

}
.buttonWidth{
  width:150px;
}

.newCommentContent{
 
   flex: 0 1 calc(50% - 10px); 
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid lightgray;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
 
 
}




 .reviewLabel {
  
  gap: 5.6px;
  flex: 1 0 0;
  width: 100%;
  display:flex;
  flex-direction:column;
  margin-top:10px;
}

.commentInput {
  padding: 1px;
  border: 1.333px solid lightgray;
  background: var(--white);
  touch-action: manipulation; 
  
}
/*.commentButton{
  text-align:center;
  color:white;
  width:200px;
  background-color:#FF5A5F;
   border:1px solid lightgray;
   border-radius: 10px;
 
}
.commentButton:hover{
  text-align:center;
  color:black;
  width:200px;
  background-color:white;
  
   
 
}
*/
.reviewOverlay {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
  z-index: 2; /* Below the popup but above the rest of the content */
  border-radius:8px;
  
}

.reviewSuccesspopup {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  background-color: #fff; /* White background */
  padding: 30px;
  

  text-align: start;
  display:flex;
  flex-direction:column;
  justify-content:center;
  border-radius:8px;
  row-gap:10px;
  font-size:20px;
}

.reviewSuccesspopup button {
  margin-top:10px;
   background-color:LimeGreen;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius:8px;
  max-width:70px;
  align-self: flex-end;
  
 
}
.reviewButton {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color:rgb(239, 242, 247);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
 
  color: black; /* Text color */
  border: 1px solid lightgray;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.reviewButton:hover {
  background-color: lightgray; /* Change to the desired color on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  background-color:white;
}
.rewiewsmallButton {
  padding-right: 0px;
  background-color:white;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:600;
  color: black; 
  text-decoration: underline;
  border-radius: 5px;
  transition: all 0.3s ease;
  border:none;
}

.rewiewsmallButton:hover {
  background-color:lightGray;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  
}


 @media only screen and (max-width: 1024px) {
.reviewSection{

  padding: 33.333px 15.333px;
  height:100%;
 
}
.reviewSuccesspopup {
 
  top: 60%;
  left: 50%;
  font-size:10px;
  min-width:200px;
}

}

 @media only screen and (max-width: 760px) {
 .reviewSection{

  padding: 13.333px 15.333px;
  height:100%;
 
 }
 .newCommentContent{
 
   flex: 0 1 calc(100% - 20px); 
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid lightgray;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
 
}
 
 
  h2{
  font-size:20px;
} 
   .reviewSection {
    font-size: 0.875rem; /* Equivalent to text-sm */
  }
.reviewSuccesspopup {
 
  top: 70%;
  left: 50%;
  font-size:10px;
  min-width:200px;
}
.singleCard {
    flex: 0 1 calc(100% - 20px); /* Take up 100% width on smaller screens */
  }

 }

