 .photo--section{
 
  padding: 30.333px 85.333px;
  
  
}
.photo--section h2{
  border-top:1px solid lightgray;
  padding-top:50px;
  margin-bottom:10px;
   font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  font-style:normal;
}
.potoTourPage{
position:absolute;
background-color:pink;
width:100vh;
height:100vh;
z-index:100;


}
.morePicIcon{
 width:15px;
 height:15px;
}

 .displayCoverPic {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Set the width of each column to 200px */
  grid-template-rows: repeat(2, 300px); 
  grid-gap: 16px;
  height: 100%;
  width: 100%;
  
   position: relative;
  }

.show-more-button {
  position: absolute;
  bottom: 30px;
  right: 15px;
  padding: 3px 3px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-icon {
  max-width: 15px; /* Adjust as needed */
  max-height: 15px; /* Adjust as needed */
  margin-right: 10px; /* Adjust as needed */
  vertical-align: middle;
}




.first-pic {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  position: relative;
}

.morePicIcon {
  position: absolute;
  width:50px;
  height:50px;
  bottom: 20px;
  right: 5px;
  z-index: 1; /* Ensure the icon is above the image */
}
.thesecond-pic {
  grid-column: 3;
  grid-row: 1;
  position: relative;
  
}

.thethird-pic {
  grid-column: 4;
  grid-row: 1; 
  position: relative;
}

.fourth-pic {
  grid-column: 3;
  grid-row: 2;
  position: relative;
}

.fifth-pic {
  grid-column: 4;
  grid-row: 2;
  position: relative;
}
.first-pic a,
.thesecond-pic a,
.thethird-pic a,
.fourth-pic a,
.fifth-pic a {
  display: block;
  width: 100%;
  height: 100%;
}


.first-pic img,
.thesecond-pic img,
.thethird-pic img,
.fourth-pic img,
.fifth-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}


 .first-pic img:hover,
.thesecond-pic img:hover,
.thethird-pic img:hover,
.fourth-pic img:hover,
.fifth-pic img:hover {
  transform: scale(0.98); /* Increase the scale on hover */
}






@media only screen and (max-width: 1200px) {
.photo--section{
  padding: 10.333px 85.333px;
 
}
 

}
@media only screen and (max-width: 1024px) {
 .first-pic {
  grid-column: 1 / span 4;
  grid-row: 1 / span 4;
  position: relative;
}
 
 .thesecond-pic,
  .thethird-pic,
  .fourth-pic,
  .fifth-pic {
    display: none;
  
}
.displayCoverPic {
  grid-template-rows: repeat(2, 200px); 
 }

.photo--section{
 
  padding: 0.333px 15.333px;
  
}



}
@media only screen and (max-width: 767px) {
.displayCoverPic {

  grid-template-rows: repeat(2, 150px); 
 }
.photo--section h2{
  font-size:20px;
}


}
@media only screen and (max-width: 480px) {

 .displayCoverPic {

  grid-template-rows: repeat(2, 100px); 
 }

  
 
 
  
}
@media only screen and (max-width: 410px) {

 .show-more-button {
 
  bottom: 15px;
 
  padding: 0px 0px;

  font-size:8px;
}

 .photo--section{
  display:none;
  padding: 30.333px 85.333px;
  
  
} 
  
 
  
}


